import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Link from "./Link"

export default function ArticleCard(props) {
  return (
    <Link to={"/articles/" + props.slug}>
      <li key={props.slug} className="grid grid-cols-2 items-center gap-8 pt-8">
        <div className="text-xs md:text-sm xl:text-base space-y-1">
          <h3 className="pb-2 md:pb-4 lg:pb-8">{props.title}</h3>
          <div className="flex flex-row pb-2 space-x-4">{props.tags}</div>
          <p className="text-white text-opacity-60">
            Approximate read time: {props.readingTime} mins.
          </p>
          <p className="text-white text-opacity-60">{props.publishedAt}</p>
        </div>
        <div className="relative h-0 w-full pb-full">
          <div className="absolute inset-0">{props.image}</div>
        </div>
      </li>
    </Link>
  )
}

ArticleCard.defaultProps = {
  image: (
    <StaticImage
      src="../images/articles-default.jpg"
      className="w-full h-full"
    />
  ),
}
