import React from "react"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import Link from "../../components/Link"
import ArticleCard from "../../components/ArticleCard"
import Seo from "../../components/Seo"

export default function Articles({ data }) {
  const tags = data.allGhostTag.nodes
  const posts = data.allGhostPost.nodes

  return (
    <Layout>
      <Seo title="Articles" image="/images/articles.jpg" />
      <section className="section relative text-white items-start pt-20">
        <div className="fixed inset-0 filter brightness-75">
          <StaticImage
            src="../../images/articles.jpg"
            className="h-full bg-dark-lighter"
            quality={80}
            loading="eager"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 w-full z-10 justify-items-start">
          <div className="flex flex-col justify-start">
            <h1 className="mb-8 sm:mb-halfscreen lg:mb-20">Articles</h1>
            <ul className="grid grid-cols-2 md:flex md:flex-col gap-8">
              {tags.map(tag => (
                <Link to={"/articles/" + tag.slug}>
                  <li key={tag.slug} className="relative">
                    <h3 className="hover:text-primary duration-300">
                      {tag.name}
                    </h3>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
          <ul className="grid grid-cols-1 mt-8 gap-8 divide-y divide-white border-t border-b pb-8 w-full">
            {posts.map(post => (
              <ArticleCard
                slug={post.slug}
                title={post.title}
                tags={post.tags.map(tag => (
                  <Link to={"/articles/" + tag.slug}>{tag.name}</Link>
                ))}
                readingTime={post.readingTime}
                publishedAt={post.publishedAt}
                image={
                  post.localImage ? (
                    <GatsbyImage
                      image={getImage(post.localImage)}
                      className="h-full w-full"
                    />
                  ) : undefined
                }
              />
            ))}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query articlesMainPageQuery {
    allGhostTag(filter: { postCount: { gt: 0 } }) {
      nodes {
        name
        slug
        localImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allGhostPost(sort: { fields: [featured, published_at], order: [DESC, DESC] }) {
      nodes {
        title
        slug
        localImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        publishedAt: published_at(formatString: "Do MMMM, YYYY")
        readingTime: reading_time
        tags {
          name
          slug
        }
      }
    }
  }
`
